* {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera, and Edge */
}

.general-container__clz {
    height: 100vh;
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: "nav main";
}

.non-collapsed-container__clz {
    grid-template-columns: 250px auto;
}

.collapsed-container__clz {
    grid-template-columns: 72px auto;
}

.h-100vh__clz {
    height: 100vh !important;
}

.customer-language-title__clz {
    font-size: 15px;
    font-weight: bold;
}

.fullscreen {
    object-fit: contain;
    position: fixed !important;
    top: 0px !important;
    right: 0px !important;
    bottom: 0px !important;
    left: 0px !important;
    box-sizing: border-box !important;
    min-width: 0px !important;
    max-width: none !important;
    min-height: 0px !important;
    max-height: none !important;
    width: 100% !important;
    height: 100% !important;
    transform: none !important;
    margin: 0px !important;
}
.fullscreen__web {
    object-fit: contain;

    position: fixed !important;
    top: 0px !important;
    right: 0px !important;
    bottom: 0px !important;
    left: 0px !important;
    box-sizing: border-box !important;
    min-width: 0px !important;
    max-width: none !important;
    min-height: 0px !important;
    max-height: none !important;
    width: 30% !important;
    height: 100% !important;
    transform: none !important;
    margin-left: 1350px !important;
    zoom: 50% !important;
    border: 2px solid !important;
}
.d-grid__clz {
    display: grid !important;
}
