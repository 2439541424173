.required__clz:after {
    content: " *";
    color: red;
}

.form-field-display:focus {
    border-radius: 30px;
    outline: 0;
    border-color: #000000;
}

.form-field-display {
    display: block;
    border-color: unset;
    border-radius: 30px !important;
    padding: 10px 15px !important;
    margin-bottom: 10px !important;
    font-size: 40px !important;
    height: 4vh;
    border-color: #959292;
}

.label-form-created-account_clz {
    line-height: 2;
    text-align: left;
    display: block;
    margin-bottom: 13px;
    margin-top: 20px;
    color: rgb(0, 0, 0);
    font-size: 14px;
    font-weight: 200;
    font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}

.input-container {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    height: 4rem;
    padding: 1.5rem;
    font-size: 36px;
}

.phone-input-flag {
    width: 30px;
    height: auto;
    margin-right: 8px;
}

.input-container input {
    border: none;
    outline: none;
    flex: 1 1;
    padding: 2rem;
}

.input-container input:focus-visible {
    border-color: var(--secondary-color);
}
