.menu-wrapper-nav-link__clz {
    color: white;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 0px !important;
    width: 190px;
    height: 44px;
    text-align: justify;
    white-space: nowrap !important;
    cursor: pointer !important;
}

.menu-wrapper-items-nav-link-active__clz.nav-link.active {
    color: white !important;
    background-color: unset !important;
    border-color: unset !important;
    font-weight: bold;
    border: none !important;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 0px !important;
}

.menu-wrapper-nav-link__clz:hover {
    color: rgb(187, 186, 186) !important;
}

.disable-account-creation-items__clz {
    pointer-events: none;
    opacity: 0.36;
}

.skipped-step-container {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
    padding: 0 2rem 2rem;
    height: 1rem;
}
.skipped-step-title {
    font: normal normal 500 18px/24px Segoe UI;
    width: 70%;
}
