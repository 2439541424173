.user-list-container {
    height: 38rem;
    overflow-y: scroll;
}
.valid-btn-container {
    height: 100px;
    bottom: 1rem;
}

.user-list-modal {
    height: 38rem;
    overflow-y: scroll;
    border: 1px solid #e9e3e3;
}

.user-list-validate-btn {
    height: 100px;
    bottom: 1rem;
}
.user-list-validate-btn-img {
    height: 87px;
    width: 350px;
}
.user-list-tag-btn {
    background: var(--global-color);
}
.connect-span-text {
    font: normal normal bold 35px / 47px Segoe UI;
}

.back-btn-container {
    background-color: #e9e9e9;
    height: 144px;
    border-top: 1px solid #9d9a9a;
    margin-top: 17px;
}

.version-text {
    color: rgba(0, 0, 0, 0.7);
    text-shadow: 0 1px rgba(255, 255, 255, 0.1);
    text-align: center;
}
