/* Google Fonts - Poppins */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

.cardStyle {
    border-radius: 25px;
    background-color: #fff;
    max-width: 14rem;
    height: 17rem;
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
    border: 4px solid var(--global-color);
    animation: pulse 1s infinite alternate;
    transition: border 0.3s ease;
    border-radius: 25px 25px 0 25px;
}
.image-content,
.card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 0px;
}
.image-content {
    position: relative;
    row-gap: 5px;
    padding: 25px 0;
}
.overlay {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: var(--global-color);
    border-radius: 19px 19px 0 25px;
}
.overlay::before,
.overlay::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: -40px;
    height: 40px;
    width: 40px;
    background-color: var(--global-color);
}
.overlay::after {
    border-radius: 0 25px 0 0;
    background-color: #fff;
}

.card-image {
    position: relative;
    height: 150px;
    width: 150px;
    border-radius: 50%;
    background: #fff;
    padding: 3px;
    align-items: center;
    justify-content: center;
    display: flex;
}
.card-image .card-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%;
    border: 4px solid var(--global-color);
}
.card-image span {
    font-size: x-large;
    font-weight: bold;
}
.name {
    z-index: 1;
    font-size: 18px;
    font-weight: 500;
    color: #333;
    font-weight: bold;
    margin-top: 5px;
}
.reward-name {
    z-index: 1;
    font-size: 18px;
    font-weight: 500;
    color: #333;
    font-weight: bold;
    margin-top: 1.5rem;
}
.discount-name {
    z-index: 1;
    font-size: 35px;
    font-weight: 500;
    font-weight: 700;
    width: 4rem;
}
.description {
    font-size: 12px;
    color: #707070;
    text-align: center;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    word-wrap: break-word;
    word-break: break-word;
    z-index: 9;
}
.buttonStyle {
    border: none;
    font-size: 16px;
    color: #fff;
    padding: 8px 16px;
    background-color: #4070f4;
    border-radius: 6px;
    margin: 14px;
    cursor: pointer;
    border-radius: 50%;
    transition: all 0.3s ease;
}

/* lll */

.selectedCard {
    border: 4px solid whitesmoke;
    box-shadow: 0 0 0 0 rgba(249, 250, 250, 0.9);
    animation: pulse 1s infinite alternate;
    transition: border 0.3s ease;
    border-radius: 25px 25px 0 25px;
}

.scanner-card {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 0 1rem;
    border: none;
    background-color: rgba(0, 0, 0, 0.4);
}

.scanner-button {
    background-color: #4070f4;
    font-weight: 600;
    border-radius: 30px;
    height: 4rem;
}
.scanner-button:hover,
.scanner-button:focus,
.btn-secondary:not(:disabled):not(.disabled):active {
    background-color: #eff2f8;
    font-weight: 600;
    color: #4070f4;
    border-color: #eff2f8;
    border-radius: 30px;
    height: 4rem;
}

.preview-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    word-wrap: break-word;
    word-break: break-word;
}

.product-swiper-slide {
    width: 14.4rem !important;
}

.code-promo-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-weight: 600;
    max-width: 10rem;
    width: 70%;
    height: 100%;
}
.code-promo-icons {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1rem;
    font-weight: 600;
    gap: 5px;
    height: 6.5rem;
    width: 3rem;
    padding: 0.5rem 0;
}
.category-card {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%;
}
.card-image .category-card-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%;
}
.category-card-overly {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: var(--global-color);
    border-radius: 19px 19px 0 0;
}
.category-card-content {
    background-color: var(--global-color);
    border-radius: 0 0 0 19px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 0px;
}
.code-promo-info-title {
    border: dashed 2px var(--secondary-color);
    padding: 1rem 2.5rem 1rem 2.5rem;
    background-color: var(--global-color);
}
.code-promo-info-discount {
    font-size: 2rem;
    font-weight: 700;
}
.code-promo-info-details {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
    font-size: 1.2rem;
    font-weight: 500;
}
.code-promo-info-footer {
    background-color: var(--global-color);
    width: 100%;
    text-align: center;
    padding: 20px 0;
}
.code-promo-info-value {
    color: var(--global-color);
}
.code-promo-alert-image {
    width: 12rem;
}
.code-promo-range-total {
    background-color: var(--global-color);
    color: var(--secondary-color);
}

.MuiIcon-fontSizeSmall {
    font-size: 35px !important;
}

.error-toast-container {
    width: 250px;
    height: 3rem;
}

.error-toast-msg {
    min-width: 250px;
    background-color: #f44336;
    color: white;
    text-align: center;
    border-radius: 2px;
    bottom: 30px;
    font-size: 17px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    animation: tilt-shaking 0.25s 3s;
}

.close-button {
    background: none;
    border: none;
    color: white;
    font-size: 20px;
    cursor: pointer;
}

.code-promo-only-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.2rem;
    font-weight: 600;
    width: 50rem;
    height: 100%;
    gap: 8rem;
    padding-left: 3rem;
}
.code-promo-only-icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    font-size: 1rem;
    gap: 2rem;
    font-weight: 600;
    height: 6.5rem;
    width: 8rem;
    padding: 0.5rem 0;
}

@keyframes tilt-shaking {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(5deg);
    }
    50% {
        transform: rotate(0eg);
    }
    75% {
        transform: rotate(-5deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

/* */
@media screen and (max-width: 768px) {
    .slide-content {
        margin: 0 10px;
    }
    .swiper-navBtn {
        display: none;
    }
}

.close-icon {
    align-self: end;
    margin: 0.5rem 0.5rem 0 0;
    background-color: var(--global-color);
    border-radius: 10px;
    color: var(--secondary-color);
}
