/***** cart style ****/

.header-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 1080px;
    height: 100px;
    box-shadow: 0px -5px 6px #2c2c2c15;
    border-bottom: 1px solid rgba(112, 112, 112, 0.23);
}
.prm-cart-icon {
    width: 120px;
    height: 69px;
    text-align: center;
}
.prm-number-articles {
    display: inline-block;
    margin-left: 15px;
    font: normal normal 600 20px/27px Segoe UI;
}
.cart-open-button {
    cursor: pointer;
    width: 240px;
    height: 51px;
    border: 0;
    position: relative;
    font: normal normal 300 15px/18px Segoe UI;
    color: #000000;
    align-self: flex-end;
    background: url(../Icons/png_icons/arrow-cart.png);
}
.label-open-cart {
    position: absolute;
    top: 53%;
    left: 40%;
}
.cart-pmr-button {
    display: flex;
    width: 175px;
    height: 69px;
}
.help-icon {
    cursor: pointer;
}
.pmr-icon {
    cursor: pointer;
}
.footer-contents {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 235px;
    width: 1080px;
    text-align: center;
    font: normal normal 600 20px/24px Segoe UI;
    letter-spacing: 0px;
    color: #b7b7b7;
    opacity: 1;
}
.footer-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid rgba(112, 112, 112, 0.23);
    opacity: 0.23;
    opacity: 1;
    height: 97px;
}
.prm-cancel-order {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    width: 235px;
    height: 73px;
    box-shadow: 0px 3px 6px #0000004d;
    border: 1px solid #8b8b8b;
    border-radius: 15px;
    font: normal normal 600 29px/35px Segoe UI;
    letter-spacing: 0px;
    color: #8b8b8b;
    opacity: 1;
}
button[disabled="disabled"],
button:disabled {
    opacity: 0.4;
    cursor: not-allowed;
    pointer-events: none;
    animation-name: disableButtonAnimation;
    animation-duration: 0.3s;
}
@keyframes disableButtonAnimation {
    0% {
        opacity: 1;
        cursor: pointer;
        pointer-events: auto;
    }
    100% {
        opacity: 0.4;
        cursor: not-allowed;
        pointer-events: none;
    }
}
.prm-done-order {
    cursor: pointer;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 520px;
    height: 73px;
    background: #ff8205 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000004d;
    border: 1px solid #8b8b8b;
    border-radius: 15px;
    opacity: 1;
}

.prm-label-done-order {
    width: 76px;
    text-align: center;
    font: normal normal 600 29px/35px Segoe UI;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
}
.prm-total-order {
    width: 150px;
    text-align: right;
    font: normal normal 600 29px/35px Segoe UI;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
}

/***** end cart style ****/

/***** popup cart style ********/
.header-popup {
    position: absolute;
    z-index: 1000;
    width: 1080px;
    background: white;
    top: 0;
    /* padding-top: 50px; */
    height: 200px;
}

.pmr-footer-button-popup-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid rgba(112, 112, 112, 0.23);
    opacity: 0.23;
    opacity: 1;
    height: 97px;
    position: fixed;
    bottom: 0;
    padding-left: 7px;
    padding-right: 7px;
    width: 1080px;
    background-color: #ffffff;
}
.cart-close-button {
    top: -50px;
    left: 39%;
    cursor: pointer;
    width: 240px;
    height: 51px;
    position: relative;
    font: normal normal 300 15px/18px Segoe UI;
    color: #000000;
    background: url(../Icons/png_icons/arrow-icon-close.png);
}
.cancel-order-popup-card {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 258px;
    height: 73px;
    box-shadow: 0px 3px 6px #0000004d;
    border: 1px solid #8b8b8b;
    border-radius: 15px;
    font: normal normal 600 29px/35px Segoe UI;
    letter-spacing: 0px;
    color: #8b8b8b;
    opacity: 1;
    margin-left: 10px;
}
.done-order-popup-card {
    cursor: pointer;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 770px;
    height: 73px;
    margin-right: 20px;
    background: #ff8205 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000004d;
    border: 1px solid #8b8b8b;
    border-radius: 15px;
    opacity: 1;
}
.label-close-cart {
    position: absolute;
    top: 53%;
    left: 40%;
}
.title-popup-cart {
    font: normal normal 600 58px/70px Segoe UI;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    text-align: center;
    height: 58px;
    margin-top: 80px;
}
.number-poroduct-in-cart {
    text-align: center;
    font: normal normal normal 28px/34px Segoe UI;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-top: 20px;
    height: 28px;
}
.simple-product {
    position: relative;
    margin: 0 auto;
    margin-bottom: 44px;
    width: 982px;
    min-height: 319px;
    /* UI Properties */
    background: #fdfdfc 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000080;
    border: 1px solid #f8f8f6;
    opacity: 1;
    border-radius: 15px;
}
.product-header {
    margin-top: 18px;
    margin-left: 28px;
    margin-right: 28px;
    margin-right: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.number-product {
    text-align: center;
    font: normal normal 600 40px/48px Segoe UI;
    letter-spacing: 0px;
    color: var(--global-color);
    display: inline-block;
    margin-right: 15px;
}

.price-product {
    font: normal normal 600 35px/42px Segoe UI;
    letter-spacing: 0px;
    color: #707070;
    text-transform: uppercase;
    opacity: 1;
    margin-left: 103px;
}
.product-info-container {
    height: 160px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.product-info {
    display: flex;
}
.list-compositions {
    display: flex;
    flex-wrap: wrap;
    width: 400px;
    padding-top: 30px;
    margin-left: 25px;
}
.list-compositions > div {
    flex: 1 150px;
    text-align: left;
    font: normal normal normal 23px/28px Segoe UI;
    letter-spacing: 0px;
    color: #0d0c0c;
    opacity: 1;
}

.imag-product {
    height: 155px;
    width: 116px;
    margin-left: 70px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
}
.imag-product-component {
    height: 108px;
    width: 133px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
}
.imag-icon-product {
    height: 65px;
    width: 65px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
}
.edit-product {
    height: 93px;
    align-self: center;
    margin-bottom: 10px;
}
.label-edit-product {
    font: normal normal bold 16px/22px Nunito;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}
.product-operations > * {
    cursor: pointer;
}
.product-operations {
    display: flex;
}
.details-component {
    width: 565px;
}
.cart-products-list {
    max-height: 1626px;
    overflow-y: auto;
    padding-top: 250px;
}
.product-component {
    height: 217px;
    width: 888px;
    margin: 0 auto;
    margin-top: 34px;
}
.product-component:last-of-type {
    margin-bottom: 117px;
}
.info-component {
    display: flex;
    width: 888px;
    height: 170px;
    padding-top: 20px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000004d;
    border-radius: 15px;
    opacity: 1;
}
.info-container-product {
    /* height: 125px; */
    display: flex;
    flex-direction: column;
    /* gap: 10px; */
}
.info-options-product {
    font: normal normal bold 20px/24px Segoe UI;
    color: #b7b7b7;
    margin-left: 20px;
    text-align: left;
}

.info-container-product .title-product-preview {
    margin-top: unset;
}
.simple-product-operations {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    margin: unset;
}
.number-components {
    text-align: center;
    margin-right: 10px;
    font: normal normal bold 30px/36px Segoe UI;
    letter-spacing: 0px;
    color: #ff8205;
    opacity: 1;
}
.component-name {
    font: normal normal bold 30px/36px Segoe UI;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-bottom: 8px;
}
.basic-compositions-component {
    font: normal normal normal 17px/20px Segoe UI;
    letter-spacing: 0px;
    color: #000000;
    margin-bottom: 8px;
}
.variants-component {
    width: 500px;
    /* UI Properties */
    font: normal normal normal 18px/22px Segoe UI;
    color: #3cc73a;
}
.title-component {
    font: normal normal bold 35px/47px Nunito;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}
/*** end style for popup cart *****/
/*** swiperproduct cart ***/
.swiper-product-cart {
    width: 1050px;
    height: 230px;
}
.product-swiper {
    width: 334px;
    height: 214px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000001a;
    border: 1px solid #f0f0f0;
    border-radius: 12px;
}
.imag-product-preview {
    height: 84px;
    width: 112px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-left: 10px;
    margin-top: 5px;
}
.info-image-price {
    display: flex;
    justify-content: space-between;
    width: 309px;
}
.price-product-preview {
    font: normal normal bold 22px/26px Segoe UI;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-top: 15px;
}
.title-product-preview {
    font: normal normal bold 20px/24px Segoe UI;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    text-align: left;
    margin-top: 5px;
    margin-left: 20px;
}
.compositions-product-preview {
    font: normal normal 300 15px/18px Segoe UI;
    letter-spacing: 0px;
    color: #000000;
    text-align: left;
    margin-top: 4px;
    margin-bottom: 5px;
    margin-left: 20px;
}
.actions-product-preview {
    display: flex;
    justify-content: flex-end;
    border-top: 5px solid #f5f5f5;
    width: "140px";
}
.view-more-product-preview {
    border: 0px;
    cursor: pointer;
    margin-left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 50px;
    background: #ff8205 0% 0% no-repeat padding-box;
    border-radius: 15px;
    font: normal normal normal 17px/20px Segoe UI;
    letter-spacing: 0px;
    color: #ffffff;
    align-self: center;
}
.number-product-preview {
    font: normal normal bold 22px/26px Segoe UI;
    letter-spacing: 0px;
    color: #000000;
    align-self: center;
}

/*** end swiper product ****/
.productContainer__clz {
    display: grid;
    /* grid-auto-columns: auto 1fr; */
    /* grid-template-columns: 1.1fr; */
    grid-template-rows: auto 1fr;
}

.centredItems__clz {
    display: flex;
    justify-content: center;
    align-items: center !important;
}

.pmr-menu-icon {
    height: 83px;
}

.pmr-active-dropdown {
    transform: translateY(-38%);
    background: #8c8686 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000004d;
    border: 1px solid #f8f8f6;
    border-radius: 15px;
    width: 83px;
}

.menu {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
