.modal-container {
    height: "400px";
}

.alert-image {
    width: 35%;
    height: 70%;
}

.text-container {
    height: 202px;
    font-size: 45px;
    gap: 10px;
}

.btn-container {
    height: 450px;
    gap: 10%;
}

.btn_clz {
    height: 270px;
    width: 270px;
}

.btn-text {
    font: normal normal 600 33px/49px Segoe UI;
}

.text-title {
    font-size: 50px;
    font-weight: 600;
}
.text-description {
    font-size: 40px;
}

.card-container-text {
    width: 8rem;
    font-size: 30px;
    font-weight: 700;
}

.network-error-container {
    display: flex;
    flex-direction: column;
    gap: 5rem;
}

.network-error-title {
    font: normal normal 600 55px/60px Segoe UI;
}

.network-error-message {
    font: normal normal normal 35px/47px Segoe UI;
    width: 75%;
}

.refund-cash-machine-container {
    height: 7rem;
    margin: 10px 0;
    font: normal normal 600 55px/60px Segoe UI;
}

.refund-cash-machine-text {
    font: normal normal normal 35px/47px Segoe UI;
    width: 75%;
}
.refund-cash-machine-description {
    font: normal normal normal 35px/47px Segoe UI;
    width: 59%;
}
