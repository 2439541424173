.space-key__clz {
    width: 50% !important;
}

/* Styling for each key to ensure uniform width */
.react-simple-keyboard .hg-button {
    width: 100%;
    height: 60px; /* Adjust height to match your design */
    font-size: 1.5rem; /* Adjust font size as needed */
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Styling for rows to ensure all keys are aligned properly */
.react-simple-keyboard .hg-row {
    display: flex;
}

/* Ensure all keys in a row have the same width */
.react-simple-keyboard .hg-button {
    flex: 1; /* Makes buttons take equal width in their row */
}

/* Adjust specific keys like backspace and enter */
.react-simple-keyboard .hg-button.hg-backspace,
.react-simple-keyboard .hg-button.hg-enter {
    flex: 2; /* You can make specific keys wider if needed */
}
