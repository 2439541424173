::-webkit-scrollbar {
    display: none;
}

.order-taking-prm-container {
    display: grid;
    grid-template-rows: auto min-content minmax(312px, 330px) min-content;
    grid-template-columns: auto 1fr;
    grid-template-areas:
        "prm-header prm-header "
        "prm-categories prm-breadcrumb "
        "prm-categories prm-products "
        "prm-footer prm-footer ";
    height: 100vh;
}

.order-taking-prm-layout {
    width: 100vw;
    margin: 0 auto;
}

.order-taking-prm-header {
    grid-area: prm-header;
    height: 1450px !important;
}

#prm-categories {
    grid-area: prm-categories;
    margin-top: 2px !important;
    border-right: 1px solid #cccccc;
    max-height: 20vh;
    width: max-content;
}
#prm-breadcrumb {
    grid-area: prm-breadcrumb;
    max-height: 3.5vh;
}

#prm-products {
    grid-area: prm-products;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 1fr;
    align-items: center;
    padding-left: 1rem;
}

#prm-footer {
    grid-area: prm-footer;
    height: 97px;
    justify-content: center;
    bottom: 0;
    width: 100vw;
    z-index: 1;
    background: #ffffff 0% 0% no-repeat padding-box;
    display: flex;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    gap: 10px;
}

.prm-category-title {
    display: flex;
    align-items: center;
    height: 100% !important;
    font: italic normal 600 40px/53px Segoe UI;
    letter-spacing: 0px;
    color: #000000;
    text-transform: uppercase;
    height: 70px;
    padding-left: 13px;
    background-color: white;
    z-index: 1;
}

.authenticated-user-buttons {
    height: 68px;
    width: 231px;
}

.prm-authenticated-user-font {
    display: flex;
    align-items: center;
    height: 100% !important;
    font: normal normal 25px/34px Nunito Sans;
    padding-left: 15px;
    gap: 5px;
    background-color: white;
    z-index: 1;
}
